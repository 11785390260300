import { marketingLink, technologyLink } from "./general";

// const body = document.getElementsByTagName("body")[0];
const menu = document.querySelector(".menu");
const header = document.querySelector(".header");
const headerBtn = document.querySelector(".header__menuBtn");
const menuContent = document.querySelector(".menu__content");
// const menuLanguages = document.querySelector(".menu__languages");
// menuLanguages.addEventListener("click", changeLanguage);
menuContent.addEventListener("click", handleRedirection);
headerBtn.addEventListener("click", toggleMenu);

let menuIsOpen = false;

function toggleMenu() {
  if (menuIsOpen) {
    document.documentElement.style.overflow = "auto";
    document.body.classList.remove("no-scroll");
    menu.classList.remove("animate");
    menu.classList.add("animate-back");
    header.classList.remove("menuOpen");
    // menuLanguages.classList.remove("show");
    menuIsOpen = !menuIsOpen;
    return;
  }
  document.documentElement.style.overflow = "hidden";
  document.body.classList.add("no-scroll");
  menu.classList.remove("animate-back");
  menu.classList.add("animate");
  setTimeout(() => {
    header.classList.add("menuOpen");
  }, 300);
  // menuLanguages.classList.add("show");
  menuIsOpen = !menuIsOpen;
}

// function changeLanguage(ev) {
//   if (ev.target.classList.contains("active")) {
//     return;
//   }
//   Array.from(ev.target.parentNode.children).forEach((el) => {
//     el.classList.contains("active")
//       ? el.classList.remove("active")
//       : el.classList.add("active");
//   });
// }

function handleRedirection(ev) {
  const id = ev.target.id;
  if (id === "marketing") {
    window.location.href = marketingLink;
  }
  if (id === "technology") {
    window.location.href = technologyLink;
  }
}
